.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center ;
  /* justify-content:flex-end; */
  justify-content: center;
  font-size: calc(10px + 10vmin);
  color: white;
  /* padding-top: 10vh; */
  font-family: 'Chalkduster';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-container {
      background-image: url("./images/forest-4.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100vw;
      height: 100vh;
  }

  .cat {
    size: 20px;
  }

.cat-container {
    background-image: url('./images/personaje.png');
    width: 20vw;
    height: 20vw;
    padding: 60px;
    /* height: 100%; */
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 10;
}